import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "../components/ui/grid"
import HeroCareers from "../components/hero-careers"
import Job from "../components/ui/job"
import TextGradient from "../components/ui/text-gradient"
import CtaEnterprise from "../components/cta-enterprise"

const Careers = ({ location }) => {
  const { data, availablePositions } = useStaticQuery(graphql`
    query {
      data: contentfulPageCareers {
        title
        description
        featuredImage {
          gatsbyImageData
          resize(width: 512, quality: 80) {
            src
          }
        }
        openPositionsTitle
        openPositionsTitleEyebrow
      }
      availablePositions: allContentfulJobOpening {
        nodes {
          id
          title
          slug
          location {
            title
          }
        }
      }
    }
  `)

  const title = data?.title || ""
  const description = data?.description || ""
  const thumbnail = data?.featuredImage?.resize?.src || ""
  const positionsTitle = data?.openPositionsTitle || ""
  const positionsTitleEyebrow = data?.openPositionsTitleEyebrow || ""
  const positions = availablePositions?.nodes || []

  return (
    <Layout
      location={location}
      hero={<HeroCareers />}
      beforeFooter={<CtaEnterprise />}>
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <Container>
        <div className="max-w-4xl mx-auto mt-10 mb-12 md:mb-32">
          <div className="text-center">
            <div className="mb-2 font-medium text-lg">
              {positionsTitleEyebrow}
            </div>

            <h2 className="mb-12">
              <TextGradient>{positionsTitle}</TextGradient>
            </h2>
          </div>

          {positions &&
            positions.map(job => (
              <Job
                key={job?.id}
                title={job?.title}
                url={job?.slug}
                location={job?.location?.title}
              />
            ))}
        </div>
      </Container>
    </Layout>
  )
}

export default Careers
