import * as React from "react"
import { Link } from "gatsby"

import Button from "./button"

const Job = ({ title, url, location }) => {
  return (
    <div className="relative mb-4 p-6 bg-gray-light rounded-xl lg:py-3">
      <div className="flex flex-wrap items-center">
        <Link to={url}>
          <h3 className="w-full leading-normal mb-3 lg:w-auto lg:mb-0 lg:mr-6 hover:text-blue">
            {title}
          </h3>
        </Link>

        <div className="w-full font-medium text-gray-dim lg:w-auto">
          {location}
        </div>

        <div className="w-full mt-6 ml-auto text-right lg:w-auto lg:mt-0">
          <Button label="Apply" color="outlined-blue" to={url} />
        </div>
      </div>
    </div>
  )
}

export default Job
