import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import { Container } from "../components/ui/grid"
import ShapeDown from "./ui/shape-down"
import TextGradient from "./ui/text-gradient"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="mb-8 text-white">{children}</h1>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p className="mb-6 max-w-3xl mx-auto text-lg text-white">{children}</p>
      )
    },
  },
}

const HeroCareers = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageCareers {
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
        }
      }
    `
  )

  const content = data?.heroContent || ""

  return (
    <ShapeDown>
      <Container>
        <div className="text-center">
          {content && renderRichText(content, options)}
        </div>
      </Container>
    </ShapeDown>
  )
}

export default HeroCareers
