import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import Feature from "./ui/feature"
import Button from "./ui/button"
import TextGradient from "./ui/text-gradient"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h2 className="text-white lg:mb-6 lg:text-5xl lg:pr-12">{children}</h2>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="mt-4 text-lg text-white">{children}</p>
    },
  },
}

const CtaEnterprise = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulCtaDefault {
          content {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 70
              placeholder: BLURRED
            )
            title
          }
          buttonPrimary {
            title
            url
          }
          buttonSecondary {
            title
            url
          }
        }
      }
    `
  )

  const content = data?.content || null
  const image = data?.image || null
  const buttonPrimary = data?.buttonPrimary || null
  const buttonSecondary = data?.buttonSecondary || null

  return (
    <Feature
      img={image?.gatsbyImageData}
      imgAlt={image?.title}
      imgSide="top-right"
      alignY="center"
      className="py-14 lg:pb-32">
      {content && renderRichText(content, options)}

      <div className="mt-8 space-y-4 md:space-y-0 md:space-x-4">
        <Button
          label={buttonPrimary?.title}
          to={buttonPrimary?.url}
          color="outlined-blue"
          className="block md:inline-block"
        />

        <Button
          label={buttonSecondary?.title}
          to={buttonSecondary?.url}
          className="block md:inline-block"
        />
      </div>
    </Feature>
  )
}

export default CtaEnterprise
